import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavItem, NavLink, Nav, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import Svg from "../Svg";
import moment from "moment";

const Footer = () => {
  const { i18n, t } = useTranslation("common");
  const [hoursOperation, setHoursOperation] = useState([
    {
      dayOfWeek: "Monday",
      startTime: {
        hour: 8,
        minute: 0,
        second: 0,
      },
      endTime: {
        hour: 16,
        minute: 0,
        second: 0,
      },
    },
    {
      dayOfWeek: "Tuesday",
      startTime: {
        hour: 8,
        minute: 0,
        second: 0,
      },
      endTime: {
        hour: 16,
        minute: 0,
        second: 0,
      },
    },
    {
      dayOfWeek: "Wednesday",
      startTime: {
        hour: 8,
        minute: 0,
        second: 0,
      },
      endTime: {
        hour: 16,
        minute: 0,
        second: 0,
      },
    },
    {
      dayOfWeek: "Thursday",
      startTime: {
        hour: 8,
        minute: 0,
        second: 0,
      },
      endTime: {
        hour: 16,
        minute: 0,
        second: 0,
      },
    },
    {
      dayOfWeek: "Friday",
      startTime: {
        hour: 8,
        minute: 0,
        second: 0,
      },
      endTime: {
        hour: 16,
        minute: 0,
        second: 0,
      },
    },
  ]);

  // useEffect(() => {
  //   if (hoursOperation.length > 0) {
  //     return;
  //   }

  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/service-centres/information`)
  //     .then((res) => {
  //       setHoursOperation(res.data.hoursOfOperation || []);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // }, [hoursOperation]);

  return (
    <footer className="footer">
      <Container>
        <div className="d-flex flex-wrap w-full gap-32">
          <div className="d-flex flex-column flex-grow-1 items-start">
            <span className="text-white bold mb-2">{t("footer.follow")}</span>

            <Nav navbar>
              <NavItem>
                <NavLink
                  className="nav-link-icon p-0"
                  href="https://www.facebook.com/AvvyHealth"
                  id="facebook-link-footer"
                  target="_blank"
                >
                  <Svg name="facebook" className="w-16" />
                  <span className="nav-link-inner--text d-lg-none ml-2">
                    Facebook
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link-icon p-0"
                  href="https://www.instagram.com/avvyhealth/"
                  id="instagram-link-footer"
                  target="_blank"
                >
                  <Svg name="instagram" className="w-16" />
                  <span className="nav-link-inner--text d-lg-none ml-2">
                    Instagram
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link-icon p-0"
                  href="https://twitter.com/avvyhealth?s=21"
                  id="twitter-link-footer"
                  target="_blank"
                >
                  <Svg name="twitter" className="w-16" />
                  <span className="nav-link-inner--text d-lg-none ml-2">
                    Twitter
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link-icon p-0"
                  href="https://www.youtube.com/channel/UCrPiNTC-FKnNezpKxgWRGkA"
                  id="youtube-link-footer"
                  target="_blank"
                >
                  <Svg name="youtube" className="w-16" />
                  <span className="nav-link-inner--text d-lg-none ml-2">
                    YouTube
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link-icon p-0"
                  href="https://www.linkedin.com/company/avvy-health/"
                  id="linkedin-link-footer"
                  target="_blank"
                >
                  <Svg name="linkedin" className="w-16" />
                  <span className="nav-link-inner--text d-lg-none ml-2">
                    LinkedIn
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>

          <div className="d-flex flex-column">
            <span className="text-white bold mb-3">{t("footer.hours")}</span>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "minmax(96px, 25%) 1fr",
                fontSize: "12px",
              }}
            >
              {hoursOperation.map((item, index) => {
                let start = moment(
                  item.startTime.hour + ":" + item.startTime.minute,
                  ["h:mm A"]
                );
                let end = moment(
                  item.endTime.hour + ":" + item.endTime.minute,
                  ["h:mm A"]
                );
                return (
                  <React.Fragment key={index}>
                    <span className="text-white mb-0">
                      {new Intl.DateTimeFormat(i18n.language, {
                        weekday: "long",
                      }).format(moment(item.dayOfWeek, "dddd", "en").toDate())}
                    </span>
                    <span className="text-white mb-0">{`${new Intl.DateTimeFormat(
                      i18n.language,
                      {
                        timeStyle: "short",
                      }
                    )
                      .format(start)
                      .replace(/^0/, "")} - ${new Intl.DateTimeFormat(
                      i18n.language,
                      {
                        timeStyle: "short",
                      }
                    )
                      .format(end)
                      .replace(/^0+/, "")}`}</span>
                  </React.Fragment>
                );
              })}
              <span className="text-white mb-0">Saturday</span>
              <span className="text-white mb-0">Closed</span>
              <span className="text-white mb-0">Sunday</span>
              <span className="text-white mb-0">Closed</span>
            </div>

            <span className="text-white mt-3 mb-0" style={{ fontSize: "13px" }}>
              {t("footer.inquiries")}
            </span>
            <span className="text-white" style={{ fontSize: "12px" }}>
              +1 866-288-9277
            </span>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
